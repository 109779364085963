import { Component, EventEmitter, Input, OnInit, Output, ViewChild, DoCheck } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleName } from '@settings/global-settings';
import { ECSEvent, EventProvider } from '@settings/logger-monitor';
import { CabinArea, CrewAdditionalItem, DowChangesCrew } from 'src/app/weight-balance-data/weight-balance';
import { CREW_ADDITIONAL } from '@shared/const';

class DataExport {
  constructor() {
    this.schema = {
      cockpit: null,
      cabin:  null,
      name: '',
    };
    this.additionalSeats = {
      cockpit: [],
      cabin: [],
      passengerSeats: [],
    };
  }
  dow?: number | null;
  doi?: number | null;
  schema?: {
    cockpit: number | null;
    cabin: number | null;
    name: string,
  };
  additionalSeats?: {
    cockpit: CrewAdditionalItem[],
    cabin: CrewAdditionalItem[],
    passengerSeats: CrewAdditionalItem[],
  };
}

@Component({
  selector: 'app-crew-composition-modal',
  templateUrl: './crew-composition-modal.component.html',
  styleUrls: ['./crew-composition-modal.component.less']
})

export class CrewCompositionModalComponent implements OnInit, DoCheck {

  dataExport: DataExport = new DataExport();
  dataEmpty = true;

  @Input() crewComposition: Array<DowChangesCrew>;
  @Input() seats: any;
  @Input() crewCalc: DataExport;
  @Input() configuration: Array<CabinArea>;
  @Input() loading: boolean;
  @Input() preliminary: number[] = [];
  @Output() addItemEvent: EventEmitter<DataExport> = new EventEmitter<DataExport>();
  @ViewChild('closeButton') closeButton: any;
  @ViewChild('compositionForm') compositionForm: NgForm;

  error = {
    errorMessage: '',
    errorType: '',
    errorBay: '',
  };

  modalType = '';
  userAnswer = null;

  CREW_ADDITIONAL = CREW_ADDITIONAL;

  selectedComposition: DowChangesCrew;
  cabinLocations;
  cockpitLocations;
  passengersLocations;

  constructor(
    public globalI18n: GlobalI18n,
    private logger: NGXLogger,
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    if (this.dataEmpty && this.crewCalc.schema.name) {
      this.dataExport = JSON.parse(JSON.stringify(this.crewCalc));
      this.selectedComposition = this.crewComposition.find(item => {
        return item.name === this.crewCalc.schema.name
            && item.cabin === this.crewCalc.schema.cabin
            && item.cockpit === this.crewCalc.schema.cockpit;
      });
      this.dataEmpty = false;
    }
  }

  get allCount() {
    const cabin = this.dataExport.schema.cabin
                  + this.dataExport.additionalSeats.cabin.filter(el => el.role === 'crew').length
                  + this.dataExport.additionalSeats.passengerSeats.filter(el => el.role === 'crew').length;
    const cockpit = this.dataExport.schema.cockpit
                  + this.dataExport.additionalSeats.cockpit.filter(el => el.role === 'crew').length;
    const xcr = this.dataExport.additionalSeats.passengerSeats.filter(el => el.role === 'crew').length;
    const dhc = this.dataExport.additionalSeats.cabin.filter(el => el.role === 'dhc').length
                + this.dataExport.additionalSeats.cockpit.filter(el => el.role === 'dhc').length
                + this.dataExport.additionalSeats.passengerSeats.filter(el => el.role === 'dhc').length;
    const nonStandard = this.crewCalc.additionalSeats.cabin.length > 0
                      || this.crewCalc.additionalSeats.cockpit.length > 0
                      || this.crewCalc.additionalSeats.passengerSeats.length > 0;
    return {cabin, cockpit, xcr, dhc, nonStandard};
  }

  createDataExport() {
    this.dataExport = JSON.parse(JSON.stringify(this.crewCalc));
    this.selectedComposition = this.crewComposition.find(item => {
      return item.name === this.crewCalc.schema.name
          && item.cabin === this.crewCalc.schema.cabin
          && item.cockpit === this.crewCalc.schema.cockpit;
    });
    this.selectComposition();
  }

  setCabinLocations() {
    this.cabinLocations = this.selectedComposition.cabinLocations.map(el => {
      const newItem = JSON.parse(JSON.stringify(el));
      if (el.maxSeats === el.seats) {
        newItem.disabled = 1;
      }
      const count = this.dataExport.additionalSeats.cabin.filter(cabin => cabin.seat === newItem.location).length;
      if (el.maxSeats === el.seats + count) {
        newItem.disabled = 1;
      }
      newItem.free = el.maxSeats - el.seats - count;
      return newItem;
    });
  }

  setCockpitLocations() {
    this.cockpitLocations = this.selectedComposition.cockpitLocations.map(el => {
      const newItem = JSON.parse(JSON.stringify(el));
      if (el.maxSeats === el.seats) {
        newItem.disabled = 1;
      }
      const count = this.dataExport.additionalSeats.cockpit.filter(cockpit => cockpit.seat === newItem.location).length;
      if (el.maxSeats === el.seats + count) {
        newItem.disabled = 1;
      }
      newItem.free = el.maxSeats - el.seats - count;
      return newItem;
    });
  }

  setPassengerLocations() {
    this.passengersLocations = this.configuration.map(el => {
      const newItem = JSON.parse(JSON.stringify(el));
      let countPassengers = 0;
      for (const key in el.passengers) {
        if (Object.prototype.hasOwnProperty.call(el.passengers, key) && el.passengers[key]) {
          countPassengers += el.passengers[key];
        }
      }
      if (el.maxPassengers === countPassengers) {
        newItem.disabled = 1;
      }
      const countCrew = this.dataExport.additionalSeats.passengerSeats.filter(el => el.seat === newItem.name).length;
      if (el.maxPassengers === countPassengers + countCrew) {
        newItem.disabled = 1;
      }
      newItem.free = el.maxPassengers - countPassengers - countCrew;
      return newItem;
    });
  }

  selectComposition() {
    this.setCabinLocations();
    this.setCockpitLocations();
    this.setPassengerLocations();
  }

  addItem(array: Array<CrewAdditionalItem>){
    array.push(new CrewAdditionalItem());
  }

  saveComposition() {
    this.compositionForm.form.markAllAsTouched();
    if (this.compositionForm.form.valid) {
      this.closeButton.nativeElement.click();
      this.addItemEvent.emit(this.dataExport);
    }
  }

  updateDowDoiAfterCrewChange($event) {
    this.logger.info('updateDowDoiAfterCrewChange',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.updateDowDoiAfterCrewChange',
                                  'change',
                                  $event,
                                  EventProvider.UI,
                                  '',
                                  ''));
    if ($event) {
      this.dataExport.dow = $event.dow;
      this.dataExport.doi = $event.doi;
      this.dataExport.schema.cabin = $event.cabin;
      this.dataExport.schema.cockpit = $event.cockpit;
      this.dataExport.schema.name = $event.name;
    }
    this.selectedComposition = $event;
    this.selectComposition();
    this.dataExport.additionalSeats.cabin = [];
    this.dataExport.additionalSeats.cockpit = [];
  }

  async deleteElement(array: Array<CrewAdditionalItem>, index: number) {
    this.logger.info('deleteElement',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.deleteElement',
                                  'click',
                                  [array, index],
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteItem');
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        array.splice(index, 1);
      }
    });
  }


  async waitAnswer() {
    const res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 500);
   });
  }

  clearErrorMess() {
    this.logger.info('clearErrorMess',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.clearErrorMess',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.error.errorMessage = '';
    this.error.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

}
